import React from 'react'
import {createPortal} from 'react-dom'
import {
    CAlert,
    CProgress,
} from '@coreui/react'

const Alerts = ({color, message}) => {
    const [visible, setVisible] = React.useState(10)

    return createPortal(
        <>
            <CAlert
                color={color}
                show={visible}
                closeButton
                onShowChange={setVisible}
                className="alert-position"
            >
                <CProgress
                    striped
                    color="warning"
                    value={Number(visible) * 10}
                    size="xs"
                    className="mb-3"
                />
                {message}
            </CAlert>
        </>
        , document.body
    )
}

export default Alerts
