import React, {createContext, useState, useContext, useEffect, useCallback, useLayoutEffect} from 'react';
import {AuthContext} from "./AuthContext";
import {ENTRYPOINT} from "../config/entrypoint";
import {useFetch} from "../utils/dataAccess";
import {useInitialEvents} from "../actions/agenda/eventTransform";
import {isObjEmpty} from "../fonction/fonction";


export const CallUpdateContext = createContext(null)

function useGetEvent(task, token) {

    const {items: eventCatch, load: loadEvent} = useFetch(
        ENTRYPOINT + "/agenda_events/" + task?.agendaEvent,
        'GET',
        token,
    )

    const get = useCallback(() => {
        if (task) {
            loadEvent()
        }
    }, [task])

    return {
        get,
        eventCatch
    }
}

function useGetTaskFromBase() {
    let eventFromBase = {}

    const {load: loadInitial, initialEventsFromBase, loading} = useInitialEvents()

    const checkDate = useCallback(() => {
        if (eventFromBase) {
            initialEventsFromBase.forEach((e) => {
                if (new Date(new Date(new Date(e.start).toUTCString())
                        .setHours(new Date(new Date(e.start).toUTCString()).getHours() - 2))
                    <= new Date()
                    && new Date(new Date(new Date(e.end).toUTCString())
                        .setHours(new Date(new Date(e.end).toUTCString()).getHours() - 2)) >= new Date()) {
                    Object.assign(eventFromBase, e)
                }
            })
        } else {
            eventFromBase = null
        }
    }, [initialEventsFromBase, eventFromBase])

    useEffect(() => {
        if (initialEventsFromBase.length > 0 && !loading && isObjEmpty(eventFromBase)) {
            checkDate()
        }
    }, [initialEventsFromBase, loading, eventFromBase])

    const getElement = useCallback(() => {
        if (isObjEmpty(eventFromBase) && initialEventsFromBase.length === 0) {
            loadInitial()
        }
    }, [eventFromBase, initialEventsFromBase])

    return {
        getElement,
        eventFromBase
    }
}

const CallUpdateProvider = ({children}) => {
    const {auth} = useContext(AuthContext)
    const JWT_TOKEN = auth.data?.token
    const [element, setElement] = useState(null)
    const [loadedEvent, setLoadedEvent] = useState(null)

    const setTaskData = useCallback((data, type = null) => {
        if (data) {
            setElement({task: data, type: type})
        } else {
            setElement(null)
        }
    }, []);

    const {get, eventCatch} = useGetEvent(element?.task, JWT_TOKEN)

    useEffect(() => {
        if (element?.task && element?.type === "create" && element?.task?.user?.id === auth?.data?.id) {
            get()
        } else {
            setLoadedEvent(null)
        }
    }, [element, auth])

    const {getElement, eventFromBase} = useGetTaskFromBase()

    useEffect(() => {
        if (eventCatch && element) {
            if (new Date(new Date(eventCatch.eventStartAt).toUTCString()) <= new Date() && new Date(new Date(eventCatch.eventEndAt).toUTCString()) >= new Date()) {
                setLoadedEvent(eventCatch)
            } else if (new Date(new Date(new Date(eventCatch.eventStartAt).toUTCString()).setHours(new Date(new Date(eventCatch.eventStartAt).toUTCString()).getHours() - 2)) <= new Date() && new Date(new Date(new Date(eventCatch.eventEndAt).toUTCString()).setHours(new Date(new Date(eventCatch.eventEndAt).toUTCString()).getHours() - 2)) >= new Date()) {
                setLoadedEvent(eventCatch)
            }
        }
        return () => {
            setLoadedEvent(null)
        }
    }, [eventCatch, element])

    useLayoutEffect(() => {
        if (!element && isObjEmpty(eventFromBase)) {
            getElement()
        }
    }, [getElement, element, eventFromBase])

    useEffect(() => {
        if (!isObjEmpty(eventFromBase) && eventFromBase.task && !element) {
            const taskFromBase = Object.assign(eventFromBase.task, {agendaEvent: eventFromBase.bddId})
            setElement({task: taskFromBase, type: "create"})
        }
    }, [eventFromBase, element])

    useEffect(() => {
        if (!eventFromBase && !eventCatch && element) {
            setElement(null)
        }
    }, [eventFromBase, eventCatch, element])

    return (
        <CallUpdateContext.Provider value={{loadedEvent, setTaskData}}>
            {children}
        </CallUpdateContext.Provider>
    );
};

export default CallUpdateProvider
