export const className = (...arr) => arr.filter(Boolean).join(' ');

export function extraitNombre(str) {
  return Number(str.replace(/[^\d]/g, ""))
};

export function isObject(val) {
  if (val === null) {
    return false;
  }
  return ((typeof val === 'function') || (typeof val === 'object'));
}

export function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function numberFormat(number) {
  if (number !== "") {
    const parsedNumber = parseInt(number)
    const strNumber = parsedNumber.toString();
    const lastPart = strNumber.substring(strNumber.length - 3);
    if (strNumber.length <= 6 && strNumber !== "0") {
      const firstPart = strNumber.substring(0, strNumber.length - lastPart.length)
      return firstPart + " " + lastPart;
    } else if (strNumber.length > 6) {

    } else {
      return strNumber
    }
  }
}

export function ID(length = 6) {
  // new Date() will return current time
  // getTime() method returns the number of milliseconds* since the Unix Epoch.
  // -length to get last items on string
  return new Date().getTime().toString().slice(-length);
}

//Date function

export function getWeeksInMonth(year, month) {
  const weeks = [],
    firstDate = new Date(year, month, 1),
    lastDate = new Date(year, month + 1, 0),
    numDays = lastDate.getDate();

  let dayOfWeekCounter = firstDate.getDay();

  for (let date = 1; date <= numDays; date++) {
    if (dayOfWeekCounter === 0 || weeks.length === 0) {
      weeks.push([]);
    }
    weeks[weeks.length - 1].push(date);
    dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
  }

  return weeks
    .filter((w) => !!w.length)
    .map((w) => ({
      start: w[0],
      end: w[w.length - 1],
      dates: w,
    }));
}

export function firstDayOfCurrentWeek(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1);
}

export function lastDayOfCurrentWeek(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 4);
}

export function prevWeek(date) {
  const firstDate = firstDayOfCurrentWeek(date)
  return new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate() - 7);
}

export function isInt(n){
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n){
  return Number(n) === n && n % 1 !== 0;
}

export function play(url) {
  return new Promise(function(resolve, reject) {   // return a promise
    const audio = new Audio();                     // create audio wo/ src
    audio.preload = "auto";                      // intend to play through
    audio.autoplay = true;                       // autoplay when loaded
    audio.onerror = reject;                      // on error, reject
    audio.onended = resolve;                     // when done, resolve

    audio.src =  url ;
  });
}

export function removeDuplicates(guestArray, setRemoveDuplicate) {
  // Declare a new array
  let newArray = [];

  // Declare an empty object
  let uniqueObject = {};

  // Loop for the array elements
  for (let i in guestArray) {

    // Extract the title
    let objId = guestArray[i]['id'];

    // Use the title as the index
    uniqueObject[objId] = guestArray[i];
  }

  // Loop to push unique object into array
  for (let i in uniqueObject) {
    newArray.push(uniqueObject[i]);
  }

  // Display the unique objects
  setRemoveDuplicate(newArray);
}

export function humanise (diff) {
  // The string we're working with to create the representation
  let str = '';
  // Map lengths of `diff` to different time periods
  let values = [[' année', 354], [' mois', 31], [' jours', 1]];

  // Iterate over the values...
  for (let i=0;i<values.length;i++) {
    let amount = Math.floor(diff / values[i][1]);

    // ... and find the largest time value that fits into the diff
    if (amount >= 1) {
      // If we match, add to the string ('s' is for pluralization)
      str += amount + values[i][0]  + ' ';

      // and subtract from the diff
      diff -= amount * values[i][1];
    }
  }

  return str;
}
