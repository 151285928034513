import React, {useState, useCallback, useContext, useEffect} from "react";
import {useFetch} from "../../utils/dataAccess";
import {ENTRYPOINT} from "../../config/entrypoint";
import {AuthContext} from "../../contexts/AuthContext";

export function useInitialEvents() {
    const {auth} = useContext(AuthContext)
    const JWT_TOKEN = auth?.data?.token
    const [initialEventsFromBase, setInitialEvents] = useState([])
    const [loading, setLoading] = useState(false)

    const onSuccess = useCallback((events) => {
        const array = []
        events["hydra:member"].forEach(e => {
            const eventForAgenda = {
                id: e.publicId,
                title: e.title,
                start: e.eventStartAt,
                end: e.eventEndAt,
                bddId: e.id,
                task: e.task,
                appointment: e.appointment,
                userId: e.user.id,
                comment: e.eventComment,
                color: e.user.userParameter.filter(up => up.label === 'color').length > 0
                    && e.user.userParameter.filter(up => up.label === 'color')[0].value
            }
            array.push(eventForAgenda)
        })
        setInitialEvents(array)
        setLoading(false)
    }, [])

    const {load: loadEvents} = useFetch(
        (auth?.data?.role.includes("ROLE_DIRECTOR") || auth?.data?.role.includes("ROLE_MANAGER"))
            ? ENTRYPOINT + "/agenda_events"
            : ENTRYPOINT + "/agenda_events/?user=" + auth?.data?.id,
        'GET',
        JWT_TOKEN,
        onSuccess
    )

    const load = useCallback(() => {
        if(auth && auth.data){
            setLoading(true)
            loadEvents()
        }
    }, [loadEvents, auth])

    return {
        initialEventsFromBase,
        load,
        loading
    }
}


