import {useState, useCallback} from 'react'

async function jsonLdFetch(url, method = 'GET', token = null, data = null) {
  let storedToken = "";
  if (token) {
    storedToken = token ? token : null
  }

  const params = {
    method: method,
    headers: {
      'accept': 'application/ld+json',
      'content-Type': 'application/json',
      'authorization': storedToken ? "Bearer " + storedToken : ""
    },
    credentials: "include",
  }

  if (data) {
    params.body = JSON.stringify(data)
  }

  const response = await fetch(url, params)

  if (response.status === 204) {
    return null
  }
  const responseData = await response.json()
  if (response.ok) {

    return responseData
  }
  throw responseData
}

export function useFetch(url, method = 'POST', token, callback = null) {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState(null)
  const [error, setError] = useState({})

  const load = useCallback(async (data = null) => {
    setLoading(true)
    try {
      const response = await jsonLdFetch(url, method, token, data)
      if (callback) {
        callback(response)
      }
      if(response) {
        if (response['hydra:member']) {
          setItems(response['hydra:member'])
        } else {
          setItems(response)
        }
      }
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [url, method, token, callback])

  return {
    loading,
    items,
    load,
    error
  }
}
