import React from 'react';
import {icons} from './assets/icons'
import ReactDOM from 'react-dom';
import {Route, Switch, HashRouter} from 'react-router-dom';
import AuthProvider from './contexts/AuthContext.js';
import PrivateRoute from './utils/privateRoute.jsx';
import CallUpdateProvider from "./contexts/CallUpdateContext";
import AuthVerify from "./common/AuthVerify";
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/coreUi.scss'

import * as serviceWorker from './serviceWorker';

import LoginForm from "./views/loginForm";

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

React.icons = icons

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

ReactDOM.render(
  (
    <AuthProvider>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <AuthVerify>
            <CallUpdateProvider>
              <Switch>
                <Route path="/login" component={LoginForm}/>
                <PrivateRoute path="/" name="home" component={TheLayout}/>
                <Route render={() => <h1>Not Found</h1>}/>
              </Switch>
            </CallUpdateProvider>
          </AuthVerify>
        </React.Suspense>
      </HashRouter>
    </AuthProvider>
  ),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
