import React, {useCallback, useContext} from "react";
import {withRouter} from "react-router-dom";
import {AuthContext} from "../contexts/AuthContext";
import cookie from 'react-cookies';


const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  const {setAuthData} = useContext(AuthContext);
  const onLogOut = useCallback(() => {
    cookie.remove('security')
    setAuthData(null)
  }, [cookie])


  const user = JSON.parse(localStorage.getItem("authData"));

  if (user) {
    const decodedJwt = parseJwt(user.token);

    if (decodedJwt.exp * 1000 < Date.now()) {
      onLogOut();
    }
  }

  return (
    <div>
      {props.children}
    </div>
)
};

export default withRouter(AuthVerify);
