import React, {useContext,useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {AuthContext} from '../contexts/AuthContext.js';

const PrivateRoute = ({component: Component, ...rest}) => {
    const {auth} = useContext(AuthContext);
    const {loadingAuth} = auth;

    if (loadingAuth) {
        return (
            <Route
                {...rest}
                render={() => {
                    return <p>Loading...</p>;
                }}
            />
        );
    }

    return (
        <Route
            {...rest}
            render={routeProps => {
                return auth.data ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect to="/login"/>
                );
            }}
        />
    );
    /*  we are spreading routeProps to be able to access this routeProps in the component. */
};

export default PrivateRoute;
